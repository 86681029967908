const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

export const formatLLLL = (ISO: any) => {
  if (!ISO) {
    return ''
  }
  return dayjs(ISO).format('LLLL')
}

export const toDateWeekday = (ISO: string) => {
  if (!ISO) {
    return ''
  }
  return dayjs(ISO).format('ddd, l')
}
