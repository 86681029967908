import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Keyboard, Pressable, StyleSheet, View } from 'react-native'
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated'

import Toast from 'react-native-root-toast'
import {
  CreateClusterMemberInput,
  SearchUserQuery,
  UpdateClusterMemberInput,
  useCreateClusterMemberMutation,
  useGetMembersByClusterIdQuery,
  useRemoveClusterMemberMutation,
  useSearchUserLazyQuery,
  useUpdateClusterMemberMutation,
} from '../../../generated/graphql'
import { sortBy } from 'lodash'
import { Unpacked } from '../../../utils/types'
import { useTranslation } from 'react-i18next'
import { TitledTable } from '../../../components/TitledTable/TitledTable'
import { CenteredAnimatedActivity } from '../../../components/TitledTable/CenteredAnimatedActivity'
import {
  Button,
  Checkbox,
  DataTable,
  IconButton,
  Menu,
  Text,
  TextInput,
} from 'react-native-paper'
import SingleCell from '../../../components/TitledTable/SingleCell'
import { IconDeleteMember } from '../../../utils/meldd-icons'
import { MessageType } from '../../../utils/message-type'
import { useTheme } from '../../../hooks/use-theme'

interface Props {
  clusterId: string
}

type ListUser = Unpacked<SearchUserQuery['usersBySearchUsername']>
export const ClusterMemberList = (props: Props) => {
  const { clusterId } = props
  const { t } = useTranslation('clusters')
  const theme = useTheme()
  const [search, setSearch] = useState<string>('')
  const searchRef = useRef<any>()
  const [searchResult, setSearchResult] = useState<ListUser[]>([])
  const {
    loading,
    data: clusterMembersData,
    refetch,
    error,
  } = useGetMembersByClusterIdQuery({ variables: { clusterId } })
  const clusterMembers = useMemo(
    () =>
      sortBy(clusterMembersData?.clusterMembersByClusterId || [], [
        'user.username',
      ]),
    [clusterMembersData]
  )
  const [searchByUsername, { loading: loadingSearch, data: dataSearch }] =
    useSearchUserLazyQuery()
  useEffect(() => {
    refetch({ clusterId })
  }, [])
  const [doAddMember] = useCreateClusterMemberMutation({
    onCompleted: () => refetch(),
  })
  const [doRemoveMember] = useRemoveClusterMemberMutation({
    onCompleted: () => refetch(),
  })
  const [doUpdateMember] = useUpdateClusterMemberMutation()

  const styles = useMemo(
    () =>
      StyleSheet.create({
        row: {
          width: '100%',
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
          flexDirection: 'row',
        },
        wrapper: {
          flexGrow: 1,
          // maxHeight: 48,
          margin: 8,
          marginLeft: 20,
          // borderColor: theme.colors.border,
          borderRadius: 2,
          // borderWidth: 1,
          flexDirection: 'row',
          wrap: 'no-wrap',
          alignItems: 'center',
          alignContent: 'center',
        },
        username: {
          flexGrow: 1,
        },
        moderator: {
          flex: 0,
          flexDirection: 'row',
          wrap: 'no-wrap',
          alignItems: 'center',
          justifyContent: 'flex-end',
        },
        action: {
          flex: 0,
        },
      }),
    [theme]
  )
  const members = useMemo(() => {
    if (loading && !clusterMembersData?.clusterMembersByClusterId)
      return <SingleCell message={''} />
    if (error)
      return <SingleCell key="0" message={t('Failed to load members')} />
    return clusterMembers.map((member, ix) => {
      return (
        <Animated.View key={member.id} entering={FadeIn} exiting={FadeOut}>
          <DataTable.Row style={styles.row}>
            <View style={styles.wrapper}>
              <View style={styles.username}>
                <Text numberOfLines={1} ellipsizeMode={'tail'}>
                  {member.user.username!}
                </Text>
              </View>
              <View style={styles.moderator}>
                <Checkbox
                  onPress={() => updateMember(member.id, !member.isModerator)}
                  status={member.isModerator ? 'checked' : 'unchecked'}
                />
                <Pressable
                  style={{ maxHeight: 20, position: 'relative' }}
                  onPress={() => updateMember(member.id, !member.isModerator)}
                >
                  <Text
                    style={{
                      color: member.isModerator
                        ? theme.colors.text
                        : theme.colors.onSurfaceDisabled,
                    }}
                    variant={'labelSmall'}
                  >
                    {t('Moderator')}
                  </Text>
                </Pressable>
              </View>
              <IconButton
                style={styles.action}
                onPress={() => deleteMember(member.id)}
                icon={IconDeleteMember}
              />
            </View>
          </DataTable.Row>
        </Animated.View>
      )
    })
  }, [clusterMembers, clusterMembersData, loading, error])

  const clearInput = useCallback(() => {
    console.log('Clearing')
    Keyboard.dismiss()
    setSearchResult([])
    setSearch('')
  }, [])

  const addNewMember = useCallback(
    async (userId: string) => {
      searchRef.current?.blur()
      const input: CreateClusterMemberInput = {
        userId,
        clusterId,
        isModerator: false,
      }
      try {
        await doAddMember({
          variables: { input },
        })
      } catch ({ message, error }) {
        Toast.show(message as string, MessageType.error)
      }
      clearInput()
    },
    [clearInput]
  )

  const updateMember = useCallback(async (id: string, isModerator: boolean) => {
    const input: UpdateClusterMemberInput = {
      id,
      isModerator,
    }
    await doUpdateMember({
      variables: { input },
    }).catch(({ message, error }) => {
      Toast.show(message)
    })
  }, [])

  const deleteMember = useCallback(async (id: string) => {
    await doRemoveMember({
      variables: { id },
    }).catch(({ message, error }) => {
      Toast.show(message)
    })
  }, [])

  const searchMembers = useMemo(() => {
    console.log(JSON.stringify(searchResult))
    if (!searchResult) {
      return <></>
    }
    return (
      <>
        {searchResult.map((item, index) => {
          const isMember = clusterMembers.find(
            (member) => member.user.username === item.username
          )
          return (
            <Menu.Item
              disabled={isMember !== undefined}
              onPress={() => addNewMember(item.id)}
              key={index}
              title={item.username}
            />
          )
        })}
      </>
    )
  }, [clusterMembers, searchResult])

  async function handleSearch(input: string) {
    console.log(input, 'input here')
    setSearch(input)
    if (input.trim().length < 1) return
    const query = await searchByUsername({
      variables: { username: input.trim().toLowerCase() },
      fetchPolicy: 'network-only',
    })
    if (query.data) {
      const result: ListUser[] = query.data.usersBySearchUsername
      setSearchResult(result)
    }
  }

  return (
    <TitledTable
      header={<DataTable.Title>{t('Private Cluster Members')}</DataTable.Title>}
    >
      <View style={{ position: 'relative' }}>
        <Menu
          visible={!!search && searchResult.length > 0}
          contentStyle={{ width: '100%' }}
          anchorPosition={'bottom'}
          onDismiss={() => setSearchResult([])}
          anchor={
            <TextInput
              ref={searchRef}
              dense={true}
              style={{ margin: 4 }}
              mode={'outlined'}
              clearButtonMode={'always'}
              onChange={(evt) => {
                console.log(evt.nativeEvent.text)
              }}
              onChangeText={handleSearch}
              label={t('Search')}
              value={search}
            />
          }
        >
          {searchMembers}
        </Menu>
        <CenteredAnimatedActivity show={loading} />
        {members}
      </View>
    </TitledTable>
  )
}

export default ClusterMemberList
