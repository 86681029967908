import { StackScreenProps } from '@react-navigation/stack'
import { AppNavigatorParams } from './../../navigation/types'

const AUTH_PAGES = ['Register', 'LoginMobileNumber', 'Login']
export const goAfterLogin = ({
  navigation,
  returnPath,
}: {
  navigation: StackScreenProps<AppNavigatorParams>['navigation']
  returnPath: string
}) => {
  const prevScreen = navigation.getState().routes.slice(-2, -1)[0]
  if (returnPath) {
    const [name, queryString] = returnPath.split('?')

    const params = Object.fromEntries(new URLSearchParams(queryString))
    // Check if is actually the prev screen, if is like that we can go back, the state will persist

    if (prevScreen?.name === name && navigation.canGoBack()) {
      navigation.goBack()
      return
    }
    // If is not  (we navigate through auth), we just load the screen again
    navigation.replace(name as keyof AppNavigatorParams, params || {})
    return
  }
  if (navigation.canGoBack() && !AUTH_PAGES.includes(prevScreen?.name)) {
    navigation.goBack()
    return
  }
  navigation.replace('ClusterList', {})
}
