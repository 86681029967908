import * as React from 'react'
import { Button, Modal, Portal, Text } from 'react-native-paper'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { AppNavigation, AppNavigatorParams } from '../../navigation/types'

type LoginRequiredModal = {
  //
}

export type LoginRequiredRef = {
  open: (navigation: AppNavigation) => void
  close: () => void
}

const getReturnPath = (navigation: AppNavigation) => {
  const currentRoute = navigation?.getState().routes.slice(-1)[0]
  if (!currentRoute) {
    return undefined
  }
  const returnPath = `${currentRoute.name}?${Object.entries(currentRoute.params || {}).map(([key, value]) => `${key}=${value}`).join('&')}`
  return returnPath
}
/**
 * A little modal that appears when we login with SSO and the account doesn't exists.
 * Will allow us to register a new account based in this SSO, or login with our exisisting account
 * and linked to the SSO.
 */
export const LoginRequiredModal = React.forwardRef<
  LoginRequiredRef,
  LoginRequiredModal
>(({ }, ref) => {
  const { t } = useTranslation('login_register_reset')
  const [visible, setVisible] = React.useState(false)
  const [navigation, setNavigation] = React.useState<AppNavigation | null>(null)
  const hideModal = () => setVisible(false)
  React.useImperativeHandle(ref, () => ({
    // The Modal is used in the auth outside the navigation stack, we can not just use `useNavigation`
    open: (navigation: AppNavigation) => {
      setNavigation(navigation)
      setVisible(true)
    },
    close: () => setVisible(false),
  }))


  return (
    <>
      <Portal>
        <Modal
          visible={visible}
          onDismiss={hideModal}
          contentContainerStyle={styles.container}>
          <Text style={styles.title}>{t('login-required')}</Text>
          <Text style={{ marginBottom: 64 }}>
            {t('login-required')}
          </Text>
          <View style={styles.buttonsRow}>
            <View>

              <Button
                mode="text"

                onPress={() => setVisible(false)}>
                {t('cancel')}
              </Button>
            </View>
            <View style={styles.row}>
              <Button
                mode="contained"
                onPress={() => {
                  setVisible(false)

                  navigation?.navigate('LoginMobileNumber', { returnPath: getReturnPath(navigation) });
                }}>
                {t('Sign In')}
              </Button>


              <Button
                mode="outlined"
                onPress={() => {
                  setVisible(false)
                  navigation?.navigate('Register', { returnPath: getReturnPath(navigation) });
                }}>
                {t('Sign Up')}
              </Button>


            </View>
          </View>
        </Modal>
      </Portal>
    </>
  )
})

const styles = StyleSheet.create({
  buttonsRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  row: {
    display: 'flex',
    flex: 1,
    gap: 8,
    justifyContent: 'flex-end',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center'
  },
  container: { backgroundColor: 'white', padding: 20, margin: 20 },
  title: { fontSize: 20, fontWeight: 'bold', marginBottom: 10 },
})
