import { StyleSheet, View } from 'react-native'
import { Button, TextInput } from 'react-native-paper'
import { useEffect, useMemo, useState } from 'react'
import Toast from 'react-native-root-toast'
import { StackScreenProps } from '@react-navigation/stack'
import { AppNavigatorParams } from '../../navigation/types'
import {
  UpdateClusterInput,
  useDeleteClusterMutation,
  useUpdateClusterMutation,
} from '../../generated/graphql'
import { MessageType } from '../../utils/message-type'
import { useTranslation } from 'react-i18next'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { LabeledSwitch } from '../../components/LabeledSwitch'
import { useTheme } from '../../hooks/use-theme'
import ClusterMemberList from './components/ClusterMemberList'

export function ClusterSettingsScreen({
  navigation,
  route,
}: StackScreenProps<AppNavigatorParams, 'ClusterSettings'>) {
  const { cluster } = route.params
  const [topic, setTopic] = useState<string>('')
  const { t } = useTranslation('clusters')
  const theme = useTheme()

  const [isPrivate, setIsPrivate] = useState<boolean>(false)
  const [showModerators, setShowModerators] = useState<boolean>(false)
  const [showPerspectiveOwners, setShowPerspectiveOwners] =
    useState<boolean>(false)

  useEffect(() => {
    if (!topic) {
      setTopic(cluster.topic)
      setIsPrivate(cluster.isPrivate || false)
      setShowModerators(cluster.showModerators || false)
      setShowPerspectiveOwners(cluster.showPerspectiveOwners || false)
    }
  }, [])

  const [doUpdateCluster, { loading }] = useUpdateClusterMutation({
    refetchQueries: ['availableClusters'],
  })
  const [doDeleteCluster, { loading: deleting }] = useDeleteClusterMutation({
    update: (cache, result) => {
      const field = `availableClusters(${JSON.stringify({
        nodeId: cluster.nodeId,
      })})`
      cache.modify({
        id: 'ROOT_QUERY',
        fields: {
          [field]: (existingTaskRefs, { readField }) => {
            return existingTaskRefs.filter(
              (taskRef: any) => cluster.id !== readField('id', taskRef)
            )
          },
        },
      })
    },
  })

  const handleUpdateCluster = async () => {
    const input: UpdateClusterInput = {
      topic,
      isPrivate,
      showModerators,
      clusterId: cluster.id,
      showPerspectiveOwners,
    }
    try {
      const result = await doUpdateCluster({
        variables: { input },
      })
      navigation.pop()
      Toast.show(t('Cluster updated'))
    } catch (e) {
      Toast.show((e as Error).message), MessageType.error
    }
  }
  const handleDeleteCluster = async () => {
    try {
      const result = await doDeleteCluster({
        variables: { id: cluster.id },
      })
      Toast.show(t('Cluster deleted'))
      navigation.pop()
    } catch (e) {
      Toast.show((e as Error).message), MessageType.error
    }
  }
  function navigateBack() {
    navigation.pop()
  }
  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          flexDirection: 'column',
          padding: 20,
          alignItems: 'stretch',
          rowGap: theme.spacing(4),
        },
        containerButtons: {
          marginVertical: 20,
          //height: 350,
          flexDirection: 'row',
          justifyContent: 'space-evenly',
        },
      }),
    [theme]
  )

  return (
    <ScreenWrapper contentContainerStyle={styles.container}>
      <TextInput
        label={t('Cluster Topic')}
        onChangeText={(text) => setTopic(text)}
        value={topic}
        clearButtonMode={'while-editing'}
        mode={'outlined'}
      />
      <LabeledSwitch
        style={{ alignSelf: 'flex-end' }}
        wrapperStyle={{ alignSelf: 'flex-end', columnGap: theme.spacing(4) }}
        label={t('Is Private?')}
        value={isPrivate}
        onValueChange={setIsPrivate}
      />
      <LabeledSwitch
        style={{ alignSelf: 'flex-end' }}
        wrapperStyle={{ alignSelf: 'flex-end', columnGap: theme.spacing(4) }}
        label={t('Show Moderators')}
        value={showModerators}
        onValueChange={setShowModerators}
      />
      <LabeledSwitch
        style={{ alignSelf: 'flex-end' }}
        wrapperStyle={{ alignSelf: 'flex-end', columnGap: theme.spacing(4) }}
        label={t('Show Perspective Owners')}
        value={showPerspectiveOwners}
        onValueChange={setShowPerspectiveOwners}
      />

      <View style={styles.containerButtons}>
        <Button onPress={() => navigation.pop()} disabled={loading}>
          {t('Cancel')}
        </Button>
        <Button
          onPress={handleUpdateCluster}
          mode="contained"
          disabled={loading || !topic}
        >
          {t('Update')}
        </Button>
      </View>
      {cluster.isPrivate && <ClusterMemberList clusterId={cluster.id} />}

      <Button
        onPress={handleDeleteCluster}
        style={{ marginTop: 20 }}
        color={theme.colors.danger.main}
        mode="outlined"
        theme={{
          colors: {
            primary: theme.colors.danger.main,
            outline: theme.colors.danger.main,
          },
        }}
      >
        {t('Delete cluster')}
      </Button>
    </ScreenWrapper>
  )
}

export default ClusterSettingsScreen
