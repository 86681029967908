import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import { useAuthContext } from '../auth/auth-context'
import { StackScreenProps } from '@react-navigation/stack'
import { Text, Button } from 'react-native-paper'
import {
  useAcceptInvitationMutation,
  useCheckInvitationQuery,
} from '../generated/graphql'
import { AppNavigatorParams } from '../navigation/types'
import { useNode } from '../contexts/CurrentNodeProvider'
import { MessageType } from '../utils/message-type'
import Toast from 'react-native-root-toast'
import { useErrorTranslation } from '../utils/error-utils'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

interface IntermediateProps { }

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 'auto',
    marginTop: 20,
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginVertical: 10,
  },
  message: {
    marginVertical: 10,
    fontStyle: 'italic',
    opacity: 0.8,
  },
})

export function AcceptInvitationScreen({
  navigation,
  route,
}: StackScreenProps<AppNavigatorParams, 'AcceptInvitation'>) {
  const { refetch, setNodeId } = useNode()
  const tError = useErrorTranslation()
  const { t } = useTranslation('invitations')

  const {
    data,
    loading,
    error: loadingError,
  } = useCheckInvitationQuery({ variables: { id: route.params.invitationId } })
  const [accept, { loading: accepting, error: acceptingError }] =
    useAcceptInvitationMutation()
  const [error, setError] = React.useState<string>('')
  const invitation = useMemo(
    () =>
      data?.validInvitation.__typename == 'Invitation' && data.validInvitation,
    [data?.validInvitation]
  )
  const invitationError = useMemo(
    () =>
      (data?.validInvitation.__typename == 'InvitationError' &&
        data.validInvitation) ||
      undefined,
    [data?.validInvitation]
  )
  const errorToShow = useMemo(
    () =>
      tError(
        error ||
        invitationError?.errorMessage ||
        loadingError?.message ||
        acceptingError?.message
      ),
    [error || loadingError?.message || acceptingError?.message, invitationError]
  )
  const doAccept = React.useCallback(async () => {
    try {
      const result = await accept({
        variables: { id: route.params.invitationId },
      })
      switch (result.data?.acceptInvitation?.__typename) {
        case 'Invitation':
          await refetch(result.data.acceptInvitation.nodeId)
          navigation.goBack()
          break
        case 'InvitationError':
          switch (result.data.acceptInvitation.code) {
            case 'INVITATION_UNNECESSARY':
              await refetch(
                result.data.acceptInvitation.invitationNodeId || undefined
              )
              navigation.goBack()
              break
            default:
              setError(result.data.acceptInvitation.errorMessage)
              break
          }
      }
    } catch (e) {
      console.error(e)
      Toast.show(tError(e)!, MessageType.error)
    }
  }, [accept, route.params.invitationId])
  return (
    <View style={styles.container}>
      {loading && <Text>{t('Checking invitation...')}</Text>}
      {errorToShow && (
        <>
          <Text>{errorToShow}</Text>
          <View style={styles.buttons}>
            <Button onPress={() => navigation.goBack()}>{t('Ok')}</Button>
          </View>
        </>
      )}
      {invitation && (
        <View>
          <Text>
            {t('You are invited to join', { nodeName: invitation.node.name })}
          </Text>
          <Text style={styles.message}>{invitation.message || ''}</Text>
          <View style={styles.buttons}>
            <Button onPress={doAccept} disabled={accepting} mode={'contained'}>
              {t('Accept')}
            </Button>
            <Button onPress={() => navigation.goBack()}>{t('Ignore')}</Button>
          </View>
        </View>
      )}
      {accepting && <Text>{'Accepting invitation...'}</Text>}
    </View>
  )
}

export default AcceptInvitationScreen
