import { useEffect, useRef, useState } from 'react'
import { Platform } from 'react-native'
import { Env } from '../env'
import { useWechatSignatureMutation } from '../generated/graphql'

type WechatShareData = {
  title: string
  description: string
  image: string
}

type WechatShareHookProps = {
  sign: ReturnType<typeof useWechatSignatureMutation>[0]
  url: string
}

export type WechatShareHook = {
  isReady: boolean
  canUse: boolean | undefined
  updateShareData: (data: WechatShareData) => Promise<Boolean>
}

const WechatWebShareHook = ({ sign, url }: WechatShareHookProps) => {
  const wxRef = useRef<any>() // Extract the types
  const [isReady, setReady] = useState(false)

  useEffect(() => {
    const loadWechatScript = () => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.src = 'https://res.wx.qq.com/open/js/jweixin-1.6.0.js'
        script.onload = () => resolve(true)
        script.onerror = () => reject(false)
        document.body.appendChild(script)
      })
    }
    const configSDK = async () => {
      const wx = (window as any).wx
      let wechatSignature
      try {
        const result = await sign({
          variables: { input: { url } },
        })
        wechatSignature = result.data?.wechatSignature
      } catch (err) {
        console.error(err)
        return
      }
      if (!wechatSignature) {
        console.error('Wechat Signature is undefined')
        return
      }
      wx.config({
        debug: true, // Enable the debug mode, and the returned values of all APIs that are called will be output as alerts in the app. You can view the incoming parameters on your PC and print the parameter information in a log on PC only.
        appId: Env.WECHAT_PUBLIC_ACCOUNT_ID, // (Required) The unique identifier of the Official Account
        timestamp: wechatSignature.timestamp, // (Required) The timestamp of signature generation
        nonceStr: wechatSignature.nonce, // (Required) The random string for the signature
        signature: wechatSignature.signature, // (Required) The signature
        jsApiList: ['updateAppMessageShareData'], // (Required) The list of required JS APIs}
      })
      wx.ready(function () {
        wx.current = wx
        setReady(true)
      })
      wx.error(function (error: string) {
        console.error(error)
      })
      wxRef.current = wx
    }
    loadWechatScript().then(() => configSDK())
  }, [])

  return {
    isReady,
    canUse: isReady ? wxRef.current !== undefined : undefined,
    updateShareData: async ({
      title,
      description,
    }: WechatShareData): Promise<boolean> => {
      if (!wxRef.current) {
        return Promise.resolve(false)
      }
      // This must be called before the user potentially taps the **Share** button.
      return new Promise((resolve) =>
        wxRef.current.updateAppMessageShareData({
          title,
          desc: description,
          link: url,
          imgUrl: 'https://app.meldd.cn/static/media/meldd.jpg',
          success: function () {
            console.log('Wechat Share Data Ready')
            return resolve(true)
          },
          error: function (error: any) {
            console.error('Wechat Share Data Error')
            console.error(error)
            return resolve(false)
          },
        })
      )
    },
  }
}

const MockWrapper = () => {
  return {
    isReady: true,
    canUse: false,
    updateShareData: (): Promise<boolean> => {
      return Promise.resolve(false)
    },
  }
}
/**
 *  An abstraction to simplify the code on the screen.
 * That loads web or native libraries accordingly
 */
export const useWechatShare = (prop: WechatShareHookProps): WechatShareHook => {
  if (Platform.OS !== 'web') {
    return MockWrapper()
  }
  return WechatWebShareHook(prop)
}
