import AsyncStorage from '@react-native-async-storage/async-storage'
import QRCode from 'qrcode'
import { Platform } from 'react-native'
import Toast from 'react-native-root-toast'
import { v4 as uuidv4 } from 'uuid'
import { Env } from '../env'
import i18n from '../i18n'

const WECHAT_STATE_KEY = 'WECHAT_STATE_KEY'

export type WechatWrapper = {
  setup: () => Promise<Boolean>
  auth: (redirectPath: string) => Promise<boolean>
  isInstalled: () => Promise<boolean>
  callback: () => Promise<string>
}

export const isWeChatBrowser = () => {
  return /MicroMessenger/i.test(navigator.userAgent)
}

export const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
}

/**
 * Native wrapper is never tested as Native is not being continued by know
 * The project seems easy to handle, but I got may issues building the native part `expo-native-wechat`
 */
const WechatNativeWrapper = async (wechatId: string) => {
  const { registerApp, sendAuthRequest, isWechatInstalled } = await import(
    'expo-native-wechat'
  )
  return {
    setup: async () => {
      return (await registerApp({ appid: wechatId })) || false
    },
    auth: async (_redirect: string) => {
      // We keep this secret on the browser to compare on the redirect
      const wechatState = uuidv4()
      AsyncStorage.setItem(WECHAT_STATE_KEY, wechatState)
      await sendAuthRequest({
        scope: 'snsapi_userinfo',
        state: wechatState,
      })
      return true
    },
    isInstalled: async () => {
      const result = (await isWechatInstalled()) as unknown as {
        success: boolean
      }
      return result.success
    },
    callback: () => Promise.resolve(''),
  }
}

const WechatWebWrapper = (wechatId: string) => {
  let wxLogin: any | undefined

  // Need to be capture before on creating, after the `await` the location.search will be empty
  const urlParams = new URLSearchParams(window.location.search)
  const callbackCode = urlParams.get('code') || ''
  const callbackState = urlParams.get('state') || ''
  const wechatRedirect = urlParams.get('wechatRedirect') || ''

  const loadWechatScript = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script')
      script.src =
        'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js'
      script.onload = () => resolve(true)
      script.onerror = () => reject(false)
      document.body.appendChild(script)
    })
  }

  const desktopQRCode = (path: string): Promise<boolean> => {
    try {
      return new Promise((resolve) => {
        const redirectUri = encodeURIComponent(`https://app.meldd.cn/${path}`)
        const wechatState = uuidv4()
        AsyncStorage.setItem(WECHAT_STATE_KEY, wechatState)

        // WeChat OAuth URL (using the snsapi_login scope for getting user info)
        const wechatUrl = `https://open.weixin.qq.com/connect/qrconnect?appid=${wechatId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_login&state=${wechatState}#wechat_redirect`

        // Get the page's current viewport dimensions
        const pageWidth = window.innerWidth
        const pageHeight = window.innerHeight
        const winTop = window.screenTop ? window.screenTop : window.screenY
        const winLeft = window.screenLeft ? window.screenLeft : window.screenX

        // Define popup size
        const width = 600
        const height = 600

        // Calculate the center position relative to the current page
        const left = (pageWidth - width) / 2 + winLeft
        const top = (pageHeight - height) / 2 + winTop

        // Open the popup
        const popup = window.open(
          wechatUrl,
          'WeChatLogin',
          `width=${width},height=${height},top=${top},left=${left},scrollbars=yes,resizable=yes`
        )

        // Listen for the popup being closed
        const popupCheckInterval = setInterval(() => {
          if (popup?.closed) {
            clearInterval(popupCheckInterval)
            resolve(true)
          }
        }, 500) // Check every 500ms
      })
    } catch (err) {
      Toast.show((err as Error).toString())
      console.error('WeChat SDK failed to load', err)
      return Promise.resolve(false)
    }
  }

  const mobileQRCode = (path: string): Promise<boolean> => {
    try {
      return new Promise((resolve) => {
        const redirectUri = encodeURIComponent(`https://app.meldd.cn/${path}`)
        const wechatState = uuidv4()
        AsyncStorage.setItem(WECHAT_STATE_KEY, wechatState)

        const selector = document.getElementById('wechat-login-container')
        if (!selector) {
          return resolve(false)
        }
        const canvas = document.createElement('canvas')
        selector.appendChild(canvas)
        const wechatId = Env.WECHAT_PUBLIC_ACCOUNT_ID

        // WeChat OAuth URL (using the snsapi_userinfo scope for getting user info)
        const wechatUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${wechatId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_userinfo&state=${wechatState}#wechat_redirect`
        QRCode.toCanvas(canvas, wechatUrl, function (error: string) {
          if (error) selector.textContent = error
        })
        resolve(true)
      })
    } catch (err) {
      Toast.show((err as Error).toString())
      console.error('WeChat SDK failed to load', err)
      return Promise.resolve(false)
    }
  }

  const wechatRedirectAuth = (path: string): Promise<boolean> => {
    try {
      return new Promise((resolve, reject) => {
        const redirectUri = encodeURIComponent(`https://app.meldd.cn/${path}`)
        const wechatState = uuidv4()
        AsyncStorage.setItem(WECHAT_STATE_KEY, wechatState)

        const wechatId = Env.WECHAT_PUBLIC_ACCOUNT_ID

        // WeChat OAuth URL (using the snsapi_userinfo scope for getting user info)
        const wechatUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${wechatId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_userinfo&state=${wechatState}#wechat_redirect`
        window.location.href = wechatUrl
        resolve(true)
      })
    } catch (err) {
      Toast.show((err as Error).toString())
      console.error('WeChat SDK failed to load', err)
      return Promise.resolve(false)
    }
  }

  return {
    setup: async () => {
      if (!(await loadWechatScript())) {
        Toast.show('NO LOADED!')
        return false
      }
      return true
    },
    callback: async () => {
      // If you are in mobile, but not in wechat, we generate a code that
      // when logged in wechat will do the redirect automatically to them
      if (wechatRedirect && isWeChatBrowser()) {
        wechatRedirectAuth(wechatRedirect)
        return ''
      }
      if (!callbackState || !callbackCode) {
        return ''
      }
      const savedState = (await AsyncStorage.getItem(WECHAT_STATE_KEY)) || ''
      if (callbackState !== savedState && !Env.IS_DEVELOP) {
        Toast.show('Invalid Wechat state, please try again')
        return ''
      }
      return callbackCode
    },
    auth: (path: string): Promise<boolean> => {
      if (isWeChatBrowser()) {
        return wechatRedirectAuth(path)
      }
      if (isMobileDevice()) {
        return mobileQRCode(path)
      }
      return desktopQRCode(path)
    },
    isInstalled: () => Promise.resolve(true),
  }
}

const MockWrapper = (wechatId: string) => {}
/**
 *  An abstraction to simplify the code on the screen.
 * That loads web or native libraries accordingly
 */
export const Wechat = async (wechatId: string): Promise<WechatWrapper> => {
  if (Platform.OS !== 'web') {
    return WechatNativeWrapper(wechatId)
  }
  return WechatWebWrapper(wechatId)
}
