import Constants from 'expo-constants'
import {
  API_URL,
  LANG_CN,
  MAX_RESERVATION_DURATION,
  WEB_APP_URL,
  SENTRY_SDN,
  IOS_GOOGLE_ID,
  ANDROID_GOOGLE_ID,
  WEB_GOOGLE_ID,
  WECHAT_WEB_ID,
  WECHAT_PUBLIC_ACCOUNT_ID,
} from '@env'
import { Dimensions, Platform } from 'react-native'

function getApiUrl() {
  const apiUrl = Constants.expoConfig?.extra?.API_URL || API_URL

  if (!apiUrl) {
    throw new Error('API_URL is missing.')
  }

  return apiUrl
}
function getWebAppUrl() {
  const apiUrl = Constants.expoConfig?.extra?.WEB_APP_URL || WEB_APP_URL

  if (!apiUrl) {
    throw new Error('WEB_APP_URL is missing.')
  }

  return apiUrl
}
function getLangCn() {
  const isLangCn: string = Constants.expoConfig?.extra?.LANG_CN || LANG_CN

  return isLangCn === 'true'
}
function getBuildType() {
  const buildType = Constants.expoConfig?.extra?.BUILD_TYPE || 'development'
  return buildType
}

function getGoogleId() {
  return {
    android:
      Constants.expoConfig?.extra?.ANDROID_GOOGLE_ID || ANDROID_GOOGLE_ID,
    ios: Constants.expoConfig?.extra?.IOS_GOOGLE_ID || IOS_GOOGLE_ID,
    web: Constants.expoConfig?.extra?.WEB_GOOGLE_ID || WEB_GOOGLE_ID,
  }
}

function getWechat() {
  return {
    webId: Constants.expoConfig?.extra?.WECHAT_WEB_ID || WECHAT_WEB_ID,
    publicAccountId:
      Constants.expoConfig?.extra?.WECHAT_PUBLIC_ACCOUNT_ID ||
      WECHAT_PUBLIC_ACCOUNT_ID,
  }
}
function getMaxReservationDuration() {
  return (
    Constants.expoConfig?.extra?.MAX_RESERVATION_DURATION ||
    MAX_RESERVATION_DURATION ||
    10 * 60_000
  )
}

function getVersion() {
  return (Constants.expoGoConfig as { version: string })?.version || ''
}
function getIsCN() {
  if (Platform.OS === 'web') {
    return window.location.href.includes('.cn')
  }
  return getBuildType().includes(':cn')
}

// Is web view open in a mobiel
function isResponsiveWeb() {
  const { width } = Dimensions.get('window')
  return Platform.OS === 'web' && width <= 768
}

export const Env = {
  VERSION: getVersion(),
  API_URL: getApiUrl(),
  WEB_APP_URL: getWebAppUrl(),
  LANG_CN: getLangCn(),
  BUILD_TYPE: getBuildType(),
  IS_PRODUCTION: getBuildType().startsWith('production'),
  IS_CN: getIsCN(),
  IS_RESPONSIVE_WEB: isResponsiveWeb(),
  IS_STAGE: getBuildType().startsWith('stage'),
  IS_DEVELOP: getBuildType().startsWith('development'),
  MAX_RESERVATION_DURATION: getMaxReservationDuration(),
  SENTRY_SDN: Constants.expoConfig?.extra?.SENTRY_SDN || SENTRY_SDN || '',
  GOOGLE_LOGIN: getGoogleId(),
  WECHAT_WEB_ID: getWechat().webId,
  WECHAT_PUBLIC_ACCOUNT_ID: getWechat().publicAccountId,
}
console.log(`Using env: ${JSON.stringify(Env, null, 2)}`)
