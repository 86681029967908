import React, { useEffect, useMemo } from 'react'
import { StackScreenProps } from '@react-navigation/stack'
import { AppNavigatorParams } from '../../navigation/types'
import {
  ContributionStatus,
  ContributionType,
  useContributionsForPerspectiveQuery,
} from '../../generated/graphql'
import { useIsFocused } from '@react-navigation/native'
import { Contribution } from './types'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { NonEditableContributionList } from './components/NonEditableContributionList'
import { useGlobalStyles } from '../../contexts/GlobalStylesProvider'
import { SocketEvents, useSocketRoom } from '../../socket/socket.hooks'

export function PerspectiveContributionsScreen({
  route,
}: StackScreenProps<AppNavigatorParams, 'PerspectiveContributions'>) {
  const isFocused = useIsFocused()
  const { articleId, contributionTypeFilter, contributionStatusFilter } =
    route.params
  const globalStyles = useGlobalStyles()
  const { loading, data, refetch } = useContributionsForPerspectiveQuery({
    variables: {
      articleId,
    },
    fetchPolicy: 'cache-and-network',
  })
  useSocketRoom(articleId).event(SocketEvents.REFRESH, refetch)
  useEffect(() => {
    if (isFocused) {
      refetch()
    }
  }, [isFocused])
  const contributions: Contribution[] = useMemo(
    () =>
      (data?.contributionsByPerspective || []).filter(
        (c) =>
          c.contributionType != ContributionType.Reserved &&
          c.status != ContributionStatus.Cancelled &&
          c.status != ContributionStatus.Censured
      ),
    [data]
  )
  return (
    <ScreenWrapper withScrollView={false} style={globalStyles.pageContainer}>
      <NonEditableContributionList
        contributions={contributions}
        initialStatusFilter={contributionStatusFilter}
        initialTypeFilter={contributionTypeFilter}
      />
    </ScreenWrapper>
  )
}
export default PerspectiveContributionsScreen
