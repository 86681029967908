import { useNavigation } from '@react-navigation/native'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { IconButton, Text } from 'react-native-paper'
import {
  User,
  useRevokeGoogleMutation,
  useRevokeWechatMutation,
  useUserQuery,
} from '../../../generated/graphql'
import { AppTheme, useTheme } from '../../../hooks/use-theme'
import {
  ConfirmDialogue,
  ConfirmDialogueRef,
} from '../../../components/ConfirmDialogue'
import GoogleLogin from '../../Login/GoogleLogin'
import WechatLogin from '../../Login/WechatLogin'

import Toast from 'react-native-root-toast'
import { MessageType } from '../../../utils/message-type'
import { Env } from '../../../env'

type Refetch = ReturnType<typeof useUserQuery>['refetch']

const GoogleAccount = ({ user, refetch }: { user: User; refetch: Refetch }) => {
  const theme = useTheme()
  const styles = makeStyle(theme)
  const { t } = useTranslation('profile')
  const revokeGoogleConfirm = React.useRef<ConfirmDialogueRef>(null)
  const [doRevokeGoogle] = useRevokeGoogleMutation()

  const revokeGoogleLink = async () => {
    try {
      await doRevokeGoogle()
      await refetch()
    } catch (e) {
      Toast.show((e as Error).message), MessageType.error
    }
  }
  if (Env.IS_CN) {
    return null
  }
  return (
    <View style={styles.rowBetween}>
      <Text>{t('Google')}</Text>
      <View style={styles.simpleRow}>
        <Text>{user?.googleSync ? user?.emailAddress : t('not-linked')}</Text>
        {!user?.googleSync ? (
          <GoogleLogin
            onSuccess={() => refetch()}
            customButton={({ disabled, onPress }) => (
              <IconButton
                style={{ margin: 0 }}
                icon="plus"
                size={20}
                disabled={disabled}
                onPress={onPress}
              />
            )}
          />
        ) : (
          <View style={{}}>
            <IconButton
              style={{ margin: 0 }}
              icon="delete"
              size={20}
              onPress={() => revokeGoogleConfirm.current?.open()}
            />
          </View>
        )}
      </View>
      <ConfirmDialogue
        label={t('Do you want to unlink your google account?')}
        ref={revokeGoogleConfirm}
        onContinue={async () => revokeGoogleLink()}
      />
    </View>
  )
}

const WechatAccount = ({ user, refetch }: { user: User; refetch: Refetch }) => {
  const theme = useTheme()
  const styles = makeStyle(theme)
  const { t } = useTranslation('profile')
  const [doRevokeWechat] = useRevokeWechatMutation()

  const revokeWechatConfirm = React.useRef<ConfirmDialogueRef>(null)

  const revokeWechatLink = async () => {
    try {
      await doRevokeWechat()
      await refetch()
    } catch (e) {
      Toast.show((e as Error).message), MessageType.error
    }
  }

  if (Env.IS_PRODUCTION && !Env.IS_CN) {
    return null
  }

  return (
    <View style={styles.rowBetween}>
      <Text>{t('Wechat')}</Text>
      <View style={styles.simpleRow}>
        <Text>{user?.wechatSync ? t('linked') : t('not-linked')}</Text>
        {!user?.wechatSync ? (
          <WechatLogin
            onSuccess={() => refetch()}
            redirectPath="WechatRedirect"
            customButton={({ disabled, onPress }) => (
              <IconButton
                style={{ margin: 0 }}
                icon="plus"
                size={20}
                disabled={disabled}
                onPress={onPress}
              />
            )}
          />
        ) : (
          <View style={{}}>
            <IconButton
              style={{ margin: 0 }}
              icon="delete"
              size={20}
              onPress={() => revokeWechatConfirm.current?.open()}
            />
          </View>
        )}
      </View>
      <ConfirmDialogue
        label={t('Do you want to unlink your Wechat account?')}
        ref={revokeWechatConfirm}
        onContinue={async () => revokeWechatLink()}
      />{' '}
    </View>
  )
}
export const SocialAccounts = ({ user }: { user: User }) => {
  const { t } = useTranslation('profile')
  const navigation = useNavigation()
  const theme = useTheme()
  const styles = makeStyle(theme)
  const { refetch } = useUserQuery({
    variables: {
      userId: user?.id,
    },
  })

  return (
    <>
      <Text style={styles.spacing} variant={'labelMedium'}>
        {t('Accounts')}
      </Text>
      <View style={styles.rowBetween}>
        <Text>{t('Mobile')}</Text>
        <View style={styles.simpleRow}>
          <Text>
            {user?.mobileNumber
              ? `+${user?.mobileCountryCode} ${user?.mobileNumber}`
              : t('not-linked')}
          </Text>
          <IconButton
            style={{ margin: 0 }}
            size={20}
            icon={user?.mobileNumber ? 'pencil' : 'plus'}
            onPress={() => {
              navigation.navigate('ProfileVerifyMobile')
            }}
          />
        </View>
      </View>
      <GoogleAccount user={user} refetch={refetch} />
      <WechatAccount user={user} refetch={refetch} />
    </>
  )
}

const makeStyle = (theme: AppTheme) => {
  return StyleSheet.create({
    simpleRow: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    row: {
      flexDirection: 'row',
      columnGap: theme.spacing(2),
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    rowBetween: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    spacing: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  })
}
