import * as React from 'react'
import { View, StyleSheet } from 'react-native'
import { Text, SegmentedButtons, IconButton } from 'react-native-paper'
import {
  Icon3dBumpDown,
  Icon3dBumpUp,
  IconBumpList,
} from '../../../utils/meldd-icons'
import { useTheme } from '../../../hooks/use-theme'
import { useCallback, useMemo } from 'react'
import {
  BumpContributionInput,
  ContributionBumpDetailFragmentDoc,
  useBumpContributionMutation,
  useBumpsForContributionQuery,
} from '../../../generated/graphql'
import _orderBy from 'lodash/orderBy'
import { useAuthContext } from '../../../auth/auth-context'
import { useFeatures } from '../../../contexts/FeatureProvider'
import { MessageType } from '../../../utils/message-type'
import Toast from 'react-native-root-toast'
import { useTranslation } from 'react-i18next'
import { useBumpDetailsProvider } from './BumpDetailsProvider'
import {
  CANCEL_BUMP,
  useBumpJustificationDialog,
} from './BumpJustificationProvider'
import { MelddToolTip } from '../../../utils/meldd-tooltip'
import { SocketEvents, useSocketRoom } from '../../../socket/socket.hooks'
import { useNavigation } from '@react-navigation/native'
import { AppNavigation } from '../../../navigation/types'

interface BumpButtonsProps {
  userId: string
  isContributor: boolean
  contributionId: string
  // article: Article
}

export function BumpButtons(props: BumpButtonsProps) {
  const { contributionId, isContributor, userId } = props
  const { ownerBumps } = useFeatures()
  const { t } = useTranslation('bumps')
  const { authAction } = useAuthContext()
  const navigation = useNavigation<AppNavigation>()

  const showBumpDetails = useBumpDetailsProvider()
  const theme = useTheme()
  const [doBumpContribution, { loading: isBumping }] =
    useBumpContributionMutation({
      update: (cache, result) => {
        const { data } = result
        if (data) {
          // Add the bump to the list if id didn't exist
          const field = `contributionBumpsByContribution(${JSON.stringify({
            contributionId,
          })})`
          cache.modify({
            id: 'ROOT_QUERY',
            fields: {
              [field]: (currentBumps: any[], { readField }) => {
                const newBumpRef = cache.writeFragment({
                  data: data.bumpContribution,
                  fragment: ContributionBumpDetailFragmentDoc,
                })
                const newBumpRefStr = JSON.stringify(newBumpRef)
                if (
                  currentBumps.some(
                    (ref) => JSON.stringify(ref) == newBumpRefStr
                  )
                ) {
                  return currentBumps
                }
                return [...currentBumps, newBumpRef]
              },
            },
          })
        }
      },
    })
  const { data, loading: isLoading, refetch } = useBumpsForContributionQuery({
    variables: { contributionId },
  })
  useSocketRoom(contributionId).event(SocketEvents.REFRESH, refetch)
  const bumps = useMemo(
    () =>
      _orderBy(
        data?.contributionBumpsByContribution || [],
        'updatedAt',
        'desc'
      ),
    [data]
  )
  const myBumpValue = useMemo(
    () => bumps.find((b) => b.userId == userId)?.value || 0,
    [bumps]
  )
  const positiveCount = useMemo(
    () => bumps.reduce((v, b) => (b.value > 0 ? v + 1 : v), 0),
    [bumps]
  )
  const negativeCount = useMemo(
    () => bumps.reduce((v, b) => (b.value < 0 ? v - 1 : v), 0),
    [bumps]
  )
  const setSaveReasonCallback = useBumpJustificationDialog()
  const canBump = useMemo(
    () => !isBumping && (!isContributor || ownerBumps),
    [isContributor, ownerBumps, isBumping]
  )
  const bump = async (value: number, showReasonInput: boolean) => {
    if (showReasonInput) {
      const callback = async (reason: string | typeof CANCEL_BUMP) => {
        console.log('save reason', value, reason)
        if (reason != CANCEL_BUMP) {
          await saveBump(value, reason)
        } else {
          setSaveReasonCallback(null)
        }
      }
      authAction(navigation, () => setSaveReasonCallback(callback))
      return
    }
    // ELSE
    authAction(navigation, () => saveBump(value, ''))

  }
  const saveBump = async (addValue: number, reason: string) => {
    const value = myBumpValue + addValue
    if (Math.abs(value) <= 1) {
      const input: BumpContributionInput = {
        contributionId,
        value: value,
        reason,
      }
      try {
        const result = await doBumpContribution({ variables: { input } })
        Toast.show(t('Bump saved'), MessageType.info)
      } catch (e) {
        Toast.show((e as Error).message, MessageType.error)
      }
    }
    setSaveReasonCallback(null)
  }
  const handleClick = useCallback(
    (value: string) => {

      const showReason = myBumpValue == 0
      bump(value == 'up' ? 1 : -1, showReason)
    },
    [myBumpValue]
  )

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
        },
        up: {
          minWidth: 0,
          maxWidth: 72,
          backgroundColor:
            myBumpValue > 0 ? theme.colors.success.surface : undefined,
        },
        down: {
          minWidth: 0,
          maxWidth: 72,
          backgroundColor:
            myBumpValue < 0 ? theme.colors.danger.surface : undefined,
        },
      }),
    [myBumpValue]
  )

  return (
    <View style={styles.container}>
      <MelddToolTip title={t('Info')}>
        <IconButton icon={IconBumpList} onPress={() => showBumpDetails(bumps)} />
      </MelddToolTip>

      <SegmentedButtons
        onValueChange={(value) => authAction(navigation, () => handleClick(value))}
        value={myBumpValue > 0 ? 'up' : myBumpValue < 0 ? 'down' : ''}
        buttons={[
          {
            value: 'down',
            disabled: !canBump,
            label: '' + negativeCount,
            icon: () => <Icon3dBumpDown size={20} onPress={() => authAction(navigation, () => handleClick('down'))} mode='icon' />,
            style: styles.down,
          },
          {
            value: 'up',
            disabled: !canBump,
            label: '' + positiveCount,
            icon: () => <Icon3dBumpUp size={20} onPress={() => authAction(navigation, () => handleClick('up'))} mode='icon' />,
            style: styles.up,
          },
        ]}
      />
    </View>
  )
}
