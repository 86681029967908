import React, { JSXElementConstructor, ReactElement } from "react";
import { Platform, Touchable, View } from 'react-native'
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import { Button, IconButton, Text, Tooltip, useTheme } from 'react-native-paper'

export const MelddToolTip = ({ title, children }: { title: string, children: ReactElement<any, string | JSXElementConstructor<any>> }) => {
  // TODO: Use the theme instead of the hardcode colors, The current theme colors didnt look good
  const theme = useTheme();
  return (
    <Tooltip
      title={title}
      theme={{ ...theme, colors: { onSurface: '#DEE1F9', surface: theme.colors.onSurface }, }}
      enterTouchDelay={Platform.OS === 'web' ? 700 : 500}
      leaveTouchDelay={200}>
      {children}
    </Tooltip>
  )
}

export type TouchableIconWithToolTipTypes = { icon: JSX.Element, tooltip: string, mode?: 'button' | 'icon' } & { onPress?: () => void, disabled?: boolean, label?: string | JSX.Element }

export const TouchableIconWithToolTip = ({ icon, tooltip, label, onPress, disabled, mode = "button" }: TouchableIconWithToolTipTypes) => {
  const Wrapper = tooltip ? MelddToolTip : View
  if (label) {

    // In WEB the hoover doesn`t seems to work on the button unless is just the in-mediate parent
    if (Platform.OS === 'web') {
      return (
        <Button onPress={onPress} disabled={disabled} icon={() => <Wrapper title={tooltip || ''}>{icon}</Wrapper>}>
          {label}
        </Button>
      )
    }
    return (
      <Wrapper title={tooltip || ''}>
        <Button onPress={onPress} icon={() => icon} disabled={disabled} >
          {label}
        </Button>
      </Wrapper>
    )
  }
  if (onPress) {
    if (mode === 'button') {
      return (
        <Wrapper title={tooltip || ''}>
          <IconButton
            icon={() => icon}
            disabled={disabled}
            onPress={onPress} />
        </Wrapper>
      )
    }
    // Some icons are clickable but making them buttons breaks the design
    return (
      <Wrapper title={tooltip || ''}>
        <TouchableWithoutFeedback onPress={onPress}>
          {icon}
        </TouchableWithoutFeedback>
      </Wrapper>
    )
  }
  return (
    <Wrapper title={tooltip || ''}>
      {icon}
    </Wrapper>
  )
}