import * as React from 'react'
import { useCallback, useMemo } from 'react'
import { Chip, Menu } from 'react-native-paper'
import { Text } from 'react-native'
import { ContributionStatus } from '../../../generated/graphql'
import { useTranslation } from 'react-i18next'

interface NodeSelectorProps {
  selected?: ContributionStatus | ContributionStatus[]
  onSelect: (value?: ContributionStatus | ContributionStatus[]) => void
}

const states = [
  { name: 'ALL', value: undefined },
  { name: ContributionStatus.Pending, value: ContributionStatus.Pending },
  {
    name: 'DECIDED',
    value: [ContributionStatus.Accepted, ContributionStatus.Rejected],
  },
  { name: ContributionStatus.Accepted, value: ContributionStatus.Accepted },
  { name: ContributionStatus.Rejected, value: ContributionStatus.Rejected },
]

export default function ContributionStatusSelector(props: NodeSelectorProps) {
  const [visible, setVisible] = React.useState(false)
  const { t } = useTranslation('contributions')
  const selectedName = useMemo(() => {
    const selectedStr = JSON.stringify(props.selected)
    return (
      states.find((s) => {
        return selectedStr == JSON.stringify(s.value)
      })?.name || ''
    )
  }, [props.selected])
  const onSelection = useCallback(
    (status?: ContributionStatus | ContributionStatus[]) => {
      setVisible(false), props.onSelect(status)
    },
    [props.onSelect]
  )
  const menuItems = useMemo(
    () =>
      states.map((statusItem) => (
        <Menu.Item
          key={statusItem.name}
          onPress={() => onSelection(statusItem.value)}
          title={t(statusItem.name)}
        />
      )),
    [t]
  )
  return (
    <Menu
      visible={visible}
      onDismiss={() => setVisible(false)}
      anchorPosition={'bottom'}
      anchor={
        <Chip
          mode="outlined"
          closeIcon={visible ? 'menu-up' : 'menu-down'}
          onPress={() => setVisible((prev) => !prev)}
          onClose={() => setVisible((prev) => !prev)}
        >
          <Text>{t(selectedName)}</Text>
        </Chip>
      }
    >
      {menuItems}
    </Menu>
  )
}
