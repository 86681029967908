import { SocketClient } from './socket-client'
import { useEffect, useState } from 'react'

export enum SocketInternalEvents {
  JOIN_ROOM = 'joinRoom',
  LEAVE_ROOM = 'leaveRoom',
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  ERROR = 'error',
}
export enum SocketEvents {
  REFRESH = 'refresh',
}
export function useSocketStatus() {
  const socket = SocketClient.get()
  const [status, setStatus] = useState<'connected' | 'disconnected'>(
    socket?.connected ? 'connected' : 'disconnected'
  )

  useEffect(() => {
    socket.on(SocketInternalEvents.CONNECT, () => {
      setStatus('connected')
    })
    socket.on(SocketInternalEvents.DISCONNECT, () => {
      setStatus('disconnected')
    })
    socket.on(SocketInternalEvents.ERROR, (error) => {
      console.error(error)
      setStatus('disconnected')
    })
    return () => {
      socket.off(SocketInternalEvents.CONNECT)
      socket.off(SocketInternalEvents.ERROR)
      socket.off(SocketInternalEvents.DISCONNECT)
    }
  }, [])

  return status
}

/**
 * Room id could be the UUID of the item of the page we are in.
 * This function is a helper that create a hook functions  useSocketRoom(ID)(HookParams)
 */
export function useSocketRoom(roomId: string | undefined) {
  const status = useSocketStatus()
  const socket = SocketClient.get()

  if (!socket) {
    throw new Error('Socket not ready')
  }
  useEffect(() => {
    if (!roomId) {
      return
    }
    if (status) {
      socket.emit(SocketInternalEvents.JOIN_ROOM, roomId)
    }
    return () => {
      socket.emit(SocketInternalEvents.LEAVE_ROOM, roomId)
    }
  }, [status, roomId])
  /**
   * Here we can emit different events linked to that room. event one is generic and accepts a SocketEvent enum
   */
  return {
    /**
     * NOTE: Don't pass a ()=> {} anonymus function as a callback, will reset the listener on every render
     */
    event: function <T = undefined>(
      event: SocketEvents,
      callback: (param?: T) => void
    ) {
      const [previousCallback, setPreviousCallback] = useState<
        (() => void) | undefined
      >()
      useEffect(() => {
        if (!roomId) {
          return
        }
        if (callback === previousCallback) {
          return
        }

        previousCallback && socket.off(event, previousCallback)
        socket.on(event, (param: { roomId: string; payload: T }) => {
          if (param.roomId !== roomId) {
            return
          }
          callback(param.payload)
        })
        setPreviousCallback(callback)
        return () => {
          socket.off(event, callback)
        }
      }, [callback, roomId])
    },
  }
}
