import * as Clipboard from 'expo-clipboard'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Image, StyleSheet, View } from 'react-native'
import {
  Button,
  Dialog,
  IconButton,
  Portal,
  Text,
} from 'react-native-paper'
import Toast from 'react-native-root-toast'
import { Env } from '../env'
import { useWechatSignatureMutation } from '../generated/graphql'
import { WechatPNG } from '../utils/meldd-icons'
import { useWechatShare } from '../utils/wechat.share'

type ShareModalProps = {
  title: string;
  url: string;
  description: string

}

export type ShareModalRef = {
  open: () => void,
  close: () => void
}


const WechatShareButton = (props: ShareModalProps & { signature: ReturnType<typeof useWechatSignatureMutation>[0] }) => {
  const { t } = useTranslation('common')
  const wechat = useWechatShare({ sign: props.signature, url: props.url })

  useEffect(() => {
    if (!wechat.isReady) {
      return
    }
    wechat.updateShareData({ title: props.title, description: props.description, image: '' })
  }, [wechat.isReady])

  const shareWechat = async () => {
    Clipboard.setStringAsync(props.url);
    Toast.show(t('Success'));
  }

  return (
    <IconButton
      mode="outlined"
      icon={() =>
        <Image source={WechatPNG} style={[{ width: 16, height: 16 }]} />
      }
      onPress={() => shareWechat()}
    />
  )
}

const CopyLinkButton = (props: ShareModalProps) => {
  const { t } = useTranslation('common')
  const copyToClipboard = async () => {
    Clipboard.setStringAsync(props.url);
    Toast.show(t('Success'));
  }

  return (
    <IconButton
      mode="outlined"
      icon={'link'}
      onPress={copyToClipboard}
    />
  )
}
export const ShareModal = forwardRef<
  ShareModalRef,
  ShareModalProps
>((props: ShareModalProps, ref) => {
  const { t } = useTranslation('share')
  const [open, setOpen] = useState(false)

  const [doSignature] = useWechatSignatureMutation()


  useImperativeHandle(ref, () => ({
    open: () => setOpen(true),
    close: () => setOpen(false)
  }))


  return (
    <>
      <IconButton
        size={20}
        icon="share"
        onPress={() => setOpen(true)}
      />
      <Portal>
        <Dialog
          visible={open}
          onDismiss={() => setOpen(false)}
          dismissable={true}
          dismissableBackButton={true}>
          <View style={styles.modalWrapper}>
            <Dialog.Title>
              <Text variant="bodyMedium">{t('Share')}</Text>
            </Dialog.Title>
            <Dialog.Content>
              <View style={styles.row}>
                <CopyLinkButton {...props} />
                <WechatShareButton {...props} signature={doSignature} />
              </View>
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={() => setOpen(false)}>{t('Cancel')}</Button>
            </Dialog.Actions>
          </View>
        </Dialog>
      </Portal>
    </>
  )
})


const styles = StyleSheet.create({
  title: { fontSize: 24, fontWeight: '600' },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 4
  },
  modalWrapper: {
    borderRadius: 4,
    margin: 32,
    zIndex: 999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
