import { createStackNavigator, StackHeaderProps } from '@react-navigation/stack'
import { AppNavigatorParams } from './types'
import { ClusterListScreen } from '../screens/ClusterList/ClusterListScreen'
import WechatRedirect from '../screens/Social/WechatRedirect'
import {
  MelddNavigationBar,
  MelddNavigationBarProps,
} from '../components/MelddNavigationBar'
import {
  NodeNavigationBar,
  NodeNavigationBarProps,
} from '../components/NodeNavigationBar'
import { VisualizationNavigationBar } from '../screens/Visualization/VisualizationNavigationBar'
import {
  ProfileNavigationBar,
  ProfileNavigationBarProps,
} from '../screens/Profile/components/ProfileNavigationBar'
import {
  PerspectiveNavigationBar,
  PerspectiveNavigationBarProps,
} from '../screens/Perspective/components/PerspectiveNavigationBar'
import React from 'react'
import { AcceptInvitationScreen } from '../screens/AcceptInvitation'
import PerspectiveScreen from '../screens/Perspective/PerspectiveScreen'
import ClusterCardsScreen from '../screens/ClusterCards/ClusterCardsScreen'
import { LoginMobileNumberScreen } from '../screens/Login/LoginMobileNumber'
import { RegisterScreen } from '../screens/Register'
import { PrivacyPolicyScreen } from '../screens/PrivacyPolicy'

/**
  * Lazy loads can not be access directly via URL on the web version.
  * For that do a normal import. 
  */
import NodeStatsScreen from '../screens/NodeStats/NodeStatsScreen'

import CreateArticleScreen from '../screens/CreateArticle'

import UpdateArticleScreen from '../screens/UpdateArticle'

import ProfileScreen from '../screens/Profile/ProfileScreen'

import ProfileVerifyMobileScreen from '../screens/Profile/ProfileVerifyMobileScreen'

import CreateClusterScreen from '../screens/Cluster/CreateClusterScreen'

import ClusterSettingsScreen from '../screens/Cluster/ClusterSettingsScreen'

import ListNodesScreen from '../screens/ListNodes/ListNodesScreen'

import NodeSettingsScreen from '../screens/NodeSettings/NodeSettingsScreen'

import VisualizationScreen from '../screens/Visualization/VisualizationScreen'

import FeatureTogglesScreen from '../screens/FeatureToggleScreen'

import PerspectiveCardsScreen from '../screens/PerspectiveCards/PerspectiveCardsScreen'
import IntroScreen from '../screens/Intro'
import PerspectiveContributionsScreen from '../screens/Contributions/PerspectiveContributionsScreen'

import ClusterContributionsScreen from '../screens/Contributions/ClusterContributionsScreen'

import DecisionsScreen from '../screens/Contributions/DecisionsScreen'

import UserContributionsScreen from '../screens/Contributions/UserContributionsScreen'
import { LoginNavigationBar } from '../components/LoginNavigationBar'
import { styles } from '../screens/NodeSettings/styles'

const Stack = createStackNavigator<AppNavigatorParams>()
// We require export the names for web3, on reload a page they need to match URI with screen
export const AppNavigatorScreens = [
  'WechatRedirect',
  "Visualization",
  "ListNodes",
  "ClusterCards",
  "PerspectiveCards",
  "PerspectiveContributions",
  "ClusterContributions",
  "NodeStats",
  "CreateArticle",
  "UpdateArticle",
  "CreateCluster",
  "Perspective",
  "ClusterSettings",
  "NodeSettings",
  "ProfileVerifyMobile",
  "Profile",
  "Decisions",
  "FeatureToggles",
  "Intro",
  "UserContributions",
  "LoginMobileNumber",
  "Register",
  "PrivacyPolicy"
];

const AppNavigator = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        header: (props: StackHeaderProps) => (
          <MelddNavigationBar {...(props as MelddNavigationBarProps)} />
        ),
      }}>

      <Stack.Screen
        name="ClusterList"
        component={ClusterListScreen}
        options={{
          header: (props: StackHeaderProps) => (
            <NodeNavigationBar {...(props as NodeNavigationBarProps)} />
          ),
        }}
      />

      <Stack.Screen name="WechatRedirect" component={WechatRedirect} options={{
        header: (props: StackHeaderProps) => (
          <NodeNavigationBar {...(props as NodeNavigationBarProps)} />
        ),
      }} />

      <Stack.Screen
        name="Visualization"
        component={VisualizationScreen}
        options={{
          header: (props: StackHeaderProps) => (
            <VisualizationNavigationBar
              {...(props as NodeNavigationBarProps)}
            />
          ),
        }}
      />

      <Stack.Screen name="ListNodes" component={ListNodesScreen} options={{
        header: (props: StackHeaderProps) => (
          <MelddNavigationBar {...(props as MelddNavigationBarProps)} back={{ title: '' }} />
        ),
      }} />

      <Stack.Screen
        name="ClusterCards"
        component={ClusterCardsScreen}
        options={{
          header: (props: StackHeaderProps) => (
            <NodeNavigationBar {...(props as NodeNavigationBarProps)} />
          ),
        }}
      />
      <Stack.Screen
        name="PerspectiveCards"
        component={PerspectiveCardsScreen}
        options={{
          header: (props: StackHeaderProps) => (
            <NodeNavigationBar {...(props as NodeNavigationBarProps)} />
          ),
        }}
      />

      <Stack.Screen
        name="PerspectiveContributions"
        component={PerspectiveContributionsScreen}
      />
      <Stack.Screen
        name="ClusterContributions"
        component={ClusterContributionsScreen}
      />

      <Stack.Screen name="NodeStats" component={NodeStatsScreen} />
      <Stack.Screen name="CreateArticle" component={CreateArticleScreen} />
      <Stack.Screen name="UpdateArticle" component={UpdateArticleScreen} />
      <Stack.Screen name="CreateCluster" component={CreateClusterScreen} />
      <Stack.Screen
        name="Perspective"
        component={PerspectiveScreen}
        options={{
          header: (props: StackHeaderProps) => (
            <PerspectiveNavigationBar
              {...(props as PerspectiveNavigationBarProps)}
            />
          ),
        }}
      />
      <Stack.Screen name="ClusterSettings" component={ClusterSettingsScreen} />
      <Stack.Screen name="NodeSettings" component={NodeSettingsScreen} />
      <Stack.Screen
        name="ProfileVerifyMobile"
        component={ProfileVerifyMobileScreen}
      />
      <Stack.Screen
        name="Profile"
        component={ProfileScreen}
        options={{
          header: (props: StackHeaderProps) => (
            <ProfileNavigationBar {...(props as ProfileNavigationBarProps)} />
          ),
        }}
      />

      <Stack.Screen name="Decisions" component={DecisionsScreen} />
      <Stack.Screen name="FeatureToggles" component={FeatureTogglesScreen} />
      <Stack.Screen name="Intro" component={IntroScreen} />
      <Stack.Screen
        name="UserContributions"
        component={UserContributionsScreen}
      />
      <Stack.Screen
        name={'AcceptInvitation'}
        component={AcceptInvitationScreen}
      />

      <Stack.Screen
        name="LoginMobileNumber"
        component={LoginMobileNumberScreen}
        options={{
          header: (props: StackHeaderProps) => (
            <LoginNavigationBar {...(props as MelddNavigationBarProps)} />
          ),
        }}
      />
      <Stack.Screen name="Register" component={RegisterScreen} options={{
        header: (props: StackHeaderProps) => (
          <LoginNavigationBar {...(props as MelddNavigationBarProps)} />
        ),
      }} />
      <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicyScreen} />
    </Stack.Navigator>
  )
}

export default AppNavigator
