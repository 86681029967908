import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { LoginMutation, LoginMutationResult } from '../generated/graphql'
import { SocketClient } from '../socket/socket-client'
import { v4 as uuidv4 } from 'uuid'
import { LoginRequiredModal, LoginRequiredRef } from '../screens/Login/login-required.modal'
import { AppNavigation, AppNavigatorParams } from '../navigation/types'
import { StackNavigationProp } from '@react-navigation/stack'

const CURRENT_USER_KEY = 'currentUser'
const LAST_USERNAME_KEY = 'lastUsername'
const LAST_USER_DATA_KEY = 'lastUserData'
const NO_USER: CurrentUser = {
  token: '',
  userId: uuidv4(), // Annonymus user id
  id: '',
  username: '',
  firstName: '',
  lastName: '',
  mobileNumber: '',
  mobileCountryCode: 0,
  bio: '',
  isAdmin: false,
  isAnonymous: true,
}

type AuthContextProviderProps = {
  children: ReactNode
}

export type AuthenticatePayload = Omit<LoginMutation['login'], '__typename'>

export type CurrentUser = LoginMutation['login']['user'] & {
  token: string
  userId: string
  bio: string
  isAnonymous: boolean
}
export type AuthContextShape = CurrentUser & {
  lastUserData: LastUserData
  loading: boolean
  setCurrentUser: (payload: AuthenticatePayload) => void
  clearCurrentUser: () => void
  updateUserData: (payload: AuthenticatePayload['user']) => void
  // Opens the login modal
  openLoginModal: (navigator: AppNavigation) => void
  // If is not logged, opens the loggin modal, otherwise executes the action
  authAction: (navigator: AppNavigation, action: () => void) => void
}

export type LastUserData = {
  username?: string
  mobileNumber?: string
  mobileCountryCode?: number
}
export const AuthContext = createContext<AuthContextShape>({
  ...NO_USER,
  loading: true,
  isAdmin: false,
  lastUserData: {},
  firstName: '',
  lastName: '',
  setCurrentUser: (_payload: AuthenticatePayload) => { },
  clearCurrentUser: () => { },
  updateUserData: (_payload: AuthenticatePayload['user']) => { },
  openLoginModal: () => { },
  authAction: () => { }
})

export const AuthContextProvider = (props: AuthContextProviderProps) => {
  const [currentUser, _setCurrentUser] = useState<CurrentUser>(NO_USER)
  const [lastUserData, setLastUserData] = useState<LastUserData>({})
  const modalRef = useRef<LoginRequiredRef>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    AsyncStorage.getItem(LAST_USER_DATA_KEY)
      .then((str) => {
        let userData: any

        try {
          userData = JSON.parse(str || '{}')
          setLastUserData(userData)
        }
        finally {
          if (!userData.username) {
            return AsyncStorage.getItem(LAST_USERNAME_KEY).then((str) => {
              if (!str) { return }
              setLastUserData({ username: str })
            })
          }
        }
      })

    AsyncStorage.getItem(CURRENT_USER_KEY).then((str) => {
      setLoading(false)

      if (str) {
        const data = JSON.parse(str) as CurrentUser
        SocketClient.init({ userId: data.userId, token: data.token })
        _setCurrentUser(data)
      } else {
        _setCurrentUser({ ...NO_USER })
        SocketClient.init({ userId: NO_USER.userId, token: '', anonymous: true })

      }
    })

  }, [])

  const setCurrentUser = useCallback((payload: AuthenticatePayload) => {

    if (!payload) {
      throw Error('payload is null')
    }
    const { accessToken, user } = payload
    if (!accessToken || !user.username || !user.id) {
      throw Error('Cannot authenticate, token or username or userId are null')
    }
    const lastUserData = {
      username: user.username,
      mobileNumber: user.mobileNumber || '',
      mobileCountryCode: user.mobileCountryCode,
    }
    const currentUser: CurrentUser = {
      ...user,
      userId: user.id,
      token: accessToken,
      isAnonymous: false,
    }
    SocketClient.init({ userId: user.id, token: accessToken })
    _setCurrentUser(currentUser)
    setLastUserData(lastUserData)
    // noinspection JSIgnoredPromiseFromCall
    AsyncStorage.setItem(CURRENT_USER_KEY, JSON.stringify(currentUser))
    // noinspection JSIgnoredPromiseFromCall
    AsyncStorage.setItem(LAST_USER_DATA_KEY, JSON.stringify(lastUserData))
  }, [])

  const updateUserData = useCallback((user: AuthenticatePayload['user']) => {

    if (!user) {
      throw Error('payload is null')
    }
    (AsyncStorage.getItem(CURRENT_USER_KEY) || '{}').then((json) => {
      if (!json) { return }
      const data = JSON.parse(json) as { token: string }
      const userUpdated: CurrentUser = {
        ...user,
        userId: user.id,
        token: data.token,
      }

      const lastUserData = {
        username: user.username,
        mobileNumber: user.mobileNumber,
        mobileCountryCode: user.mobileCountryCode,
      }
      // TODO: THis line needs to be uncommented ... but is making an infite loop. Help!
      // _setCurrentUser({ ...userUpdated })
      setLastUserData(lastUserData)
      // noinspection JSIgnoredPromiseFromCall
      AsyncStorage.setItem(CURRENT_USER_KEY, JSON.stringify(userUpdated))
      // noinspection JSIgnoredPromiseFromCall
      AsyncStorage.setItem(LAST_USER_DATA_KEY, JSON.stringify(lastUserData))
    })
  }, [])

  const clearCurrentUser = useCallback(() => {

    _setCurrentUser(NO_USER)
    SocketClient.disconnect();
    AsyncStorage.removeItem(CURRENT_USER_KEY)
  }, [])
  const openLoginModal = useCallback((navigator: AppNavigation) => {
    if (!modalRef.current) {
      return
    }
    modalRef.current.open(navigator)
  }, [modalRef.current])

  const authAction = useCallback((navigator: AppNavigation, action: () => void) => {

    if (currentUser.isAnonymous) {
      openLoginModal(navigator)
      return
    }
    action()
  }, [currentUser, openLoginModal])

  const value: AuthContextShape = {
    ...currentUser,
    lastUserData,
    updateUserData,
    setCurrentUser,
    clearCurrentUser,
    openLoginModal,
    loading,
    authAction
  }

  const { children, ...otherProps } = props
  return <AuthContext.Provider
    value={value}
    {...otherProps}
  >
    {children}
    <LoginRequiredModal ref={modalRef} />
  </AuthContext.Provider>
}

export const useAuthContext = () => useContext(AuthContext)
