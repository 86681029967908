import React, { useEffect, useMemo } from 'react'
import { StackScreenProps } from '@react-navigation/stack'
import { AppNavigatorParams } from '../../navigation/types'
import {
  ContributionStatus,
  ContributionType,
  useContributionsForClusterQuery,
} from '../../generated/graphql'
import { useIsFocused } from '@react-navigation/native'
import { Contribution } from './types'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { NonEditableContributionList } from './components/NonEditableContributionList'
import { useGlobalStyles } from '../../contexts/GlobalStylesProvider'
import { SocketEvents, useSocketRoom } from '../../socket/socket.hooks'

export function ClusterContributionsScreen({
  route,
}: StackScreenProps<AppNavigatorParams, 'ClusterContributions'>) {
  const isFocused = useIsFocused()
  const { clusterId } = route.params
  const globalStyles = useGlobalStyles()

  const { data, refetch } = useContributionsForClusterQuery({
    variables: {
      clusterId,
    },
    fetchPolicy: 'cache-and-network',
  })

  useSocketRoom(clusterId).event(SocketEvents.REFRESH, refetch)

  useEffect(() => {
    if (isFocused) {
      refetch()
    }
  }, [isFocused])
  const contributions: Contribution[] = useMemo(
    () =>
      (data?.contributionsByCluster || []).filter(
        (c) =>
          c.contributionType != ContributionType.Reserved &&
          c.status != ContributionStatus.Cancelled &&
          c.status != ContributionStatus.Censured
      ),
    [data]
  )

  return (
    <ScreenWrapper withScrollView={false} style={globalStyles.pageContainer}>
      <NonEditableContributionList contributions={contributions} />
    </ScreenWrapper>
  )
}
export default ClusterContributionsScreen
