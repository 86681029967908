import * as React from 'react'
import { useCallback, useMemo, useState } from 'react'
import { Pressable, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import {
  Button,
  Card,
  Chip,
  HelperText,
  IconButton,
  Text,
  TextInput,
} from 'react-native-paper'
import { useTheme } from '../../../hooks/use-theme'
import { Contribution } from '../types'
import { useTranslation } from 'react-i18next'
import {
  IconAccept,
  IconOriginalText,
  IconReject,
} from '../../../utils/meldd-icons'
import Toast from 'react-native-root-toast'
import { stringify } from 'flatted'
import {
  ContributionStatus,
  ContributionType,
  UpdateContributionInput,
  useUpdateContributionStatusMutation,
} from '../../../generated/graphql'
import { toDateWeekday } from '../../../utils/dayjs'
import { BumpButtons } from './BumpButtons'
import { useAuthContext } from '../../../auth/auth-context'
import { MessageType } from '../../../utils/message-type'
import { useConfirmCensureDialog } from './ConfirmCensureProvider'
import { useNavigation } from '@react-navigation/native'

interface ContributionCardProps {
  contribution: Contribution
  style?: StyleProp<ViewStyle>
  showStatus?: boolean
  showEdit?: boolean
}

export function ContributionCard(props: ContributionCardProps) {
  const { contribution, style, showStatus, showEdit } = props
  const [newResponse, setNewResponse] = useState('')

  const navigation = useNavigation()

  const theme = useTheme()
  const { t } = useTranslation('contributions')
  const { userId } = useAuthContext()
  const canDecide =
    showEdit &&
    contribution.status === ContributionStatus.Pending &&
    contribution.article.ownerId === userId
  const isRequired =
    canDecide && contribution.contributionType !== ContributionType.Edit
  const canCancel =
    contribution.status === ContributionStatus.Pending &&
    contribution.userId == userId
  const [doUpdateContributionStatus] = useUpdateContributionStatusMutation()
  const canSubmit = useMemo(
    () => canDecide && (!isRequired || !!newResponse.trim()),
    [canDecide, isRequired, newResponse]
  )
  const handleUpdateContributionStatus = useCallback(
    async (_status: ContributionStatus) => {
      if (
        _status === ContributionStatus.Accepted &&
        contribution.contributionType !== ContributionType.Edit &&
        !newResponse?.trim()
      ) {
        return Toast.show(t('Please provide a response'), MessageType.error)
      }
      const input: UpdateContributionInput = {
        contributionId: contribution.id,
        status: _status,
        response: newResponse,
      }

      try {
        const result = await doUpdateContributionStatus({
          variables: { input },
          onQueryUpdated: (query) => {
            if (query.queryName == 'contributionsForMyArticles') {
              query.refetch()
            }
          },
        })
        return Toast.show(
          t('The contribution status has been updated to', {
            status: result.data!.updateContributionStatus.status,
          }),
          MessageType.info
        )
      } catch (e) {
        return Toast.show((e as Error).message, MessageType.error)
      }
    },
    [newResponse, doUpdateContributionStatus, contribution]
  )
  const setCensureHandler = useConfirmCensureDialog()
  const handleCensure = useCallback(() => {
    setCensureHandler({
      handler: (confirm: boolean) => {
        if (confirm) {
          handleUpdateContributionStatus(ContributionStatus.Censured).then()
        }
      },
    })
  }, [setCensureHandler])
  const styles = useMemo(() => {
    const statusColor =
      theme.colors[
        contribution.status.toLowerCase() as
          | 'accepted'
          | 'rejected'
          | 'pending'
          | 'cancelled'
          | 'censured'
      ]
    const contributionColor =
      theme.colors[
        contribution.contributionType.toLowerCase() as
          | 'challenge'
          | 'edit'
          | 'inquiry'
          | 'reserved'
      ]
    const contributionBkgColor =
      theme.colors[
        (contribution.contributionType.toLowerCase() + '50') as
          | 'challenge50'
          | 'edit50'
          | 'inquiry50'
          | 'reserved50'
      ]
    return StyleSheet.create({
      container: {},
      cardHeader: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(4),
      },
      header: {
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
      title: {
        flex: 1,
      },
      date: {
        color: theme.palettes.neutral[70]!,
        marginLeft: theme.spacing(2),
      },
      typeChip: {
        borderRadius: 20,
        backgroundColor: contributionColor,
        padding: 0,
      },
      typeChipLabel: {
        color:
          contribution.contributionType === ContributionType.Edit
            ? 'black'
            : 'white',
        fontSize: 12,
        lineHeight: 15,
      },
      statusChip: {
        borderRadius: 20,
        backgroundColor: statusColor,
        padding: 0,
      },
      statusChipLabel: {
        color: 'white',
        fontSize: 12,
        lineHeight: 15,
      },
      contributionText: {
        padding: theme.spacing(1),
        backgroundColor: contributionBkgColor,
        marginBottom: theme.spacing(5),
      },
      contributionMetaText: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(5),
      },
      cancelButton: {
        borderColor: theme.colors.danger.main,
        flex: 1,
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
      },
    })
  }, [theme, contribution.status, contribution.contributionType])
  const contributionType = useMemo(() => {
    switch (contribution.contributionType) {
      case ContributionType.Challenge:
        return (
          <Chip
            mode={'flat'}
            style={styles.typeChip}
            compact
            textStyle={styles.typeChipLabel}
          >
            {t('Challenge')}
          </Chip>
        )
      case ContributionType.Edit:
        return (
          <Chip
            mode={'flat'}
            style={styles.typeChip}
            compact
            textStyle={styles.typeChipLabel}
          >
            {t('Edit')}
          </Chip>
        )
      case ContributionType.Inquiry:
        return (
          <Chip
            mode={'flat'}
            style={styles.typeChip}
            compact
            textStyle={styles.typeChipLabel}
          >
            {t('Inquiry')}
          </Chip>
        )
      case ContributionType.Reserved:
        return (
          <Chip
            mode={'flat'}
            style={styles.typeChip}
            compact
            textStyle={styles.typeChipLabel}
          >
            {t('Reserved')}
          </Chip>
        )
    }
  }, [contribution, theme])
  const status = useMemo(() => {
    switch (contribution.status) {
      case ContributionStatus.Accepted:
        return (
          <Chip
            mode={'flat'}
            style={styles.statusChip}
            compact
            textStyle={styles.statusChipLabel}
          >
            {t('Accepted')}
          </Chip>
        )
      case ContributionStatus.Cancelled:
        return (
          <Chip
            mode={'flat'}
            style={styles.statusChip}
            compact
            textStyle={styles.statusChipLabel}
          >
            {t('Cancelled')}
          </Chip>
        )
      case ContributionStatus.Censured:
        return (
          <Chip
            mode={'flat'}
            style={styles.statusChip}
            compact
            textStyle={styles.statusChipLabel}
          >
            {t('Censured')}
          </Chip>
        )
      case ContributionStatus.Pending:
        return (
          <Chip
            mode={'flat'}
            style={styles.statusChip}
            compact
            textStyle={styles.statusChipLabel}
          >
            {t('Pending')}
          </Chip>
        )
      case ContributionStatus.Rejected:
        return (
          <Chip
            mode={'flat'}
            style={styles.statusChip}
            compact
            textStyle={styles.statusChipLabel}
          >
            {t('Rejected')}
          </Chip>
        )
    }
  }, [contribution, theme])

  return (
    <Card style={[styles.container, style]} mode={'outlined'}>
      <Card.Content style={canDecide ? {} : { marginBottom: theme.spacing(3) }}>
        <View style={styles.cardHeader}>
          <Pressable
            onPress={() =>
              navigation.navigate('Perspective', {
                articleId: contribution.article.id,
                clusterId: contribution.clusterId,
                nodeId: 'cannot get it for now',
                title: contribution.article.title,
              })
            }
          >
            <Text
              style={styles.title}
              numberOfLines={4}
              ellipsizeMode={'tail'}
              variant={'titleLarge'}
            >
              {contribution.article.title}
            </Text>
          </Pressable>
          <Text style={styles.date} numberOfLines={1}>
            {toDateWeekday(contribution.createdAt)}
          </Text>
        </View>
        {showStatus && (
          <View style={[styles.header, { marginBottom: 20 }]}>
            <Text variant={'titleMedium'} numberOfLines={1}>
              {t('Status')}:
            </Text>
            {status}
          </View>
        )}
        <View style={styles.header}>
          <Text variant={'titleMedium'} numberOfLines={1}>
            {t('Type of contribution')}:
          </Text>
          {contributionType}
        </View>
        <View style={[styles.header, { marginTop: 10 }]}>
          <Text variant={'titleMedium'} numberOfLines={1}>
            {t('Original text')}:
          </Text>
          {/*<IconButton style={{marginVertical: -10}} mode={'outlined'} icon={IconOriginalText}/>*/}
        </View>
        <View style={styles.header}>
          <Text style={styles.contributionText}>
            {contribution.originalText}
          </Text>
        </View>
        {contribution.contributionType === ContributionType.Edit && (
          <>
            <View style={styles.header}>
              <Text variant={'titleMedium'} numberOfLines={1}>
                {t('Proposed text')}:
              </Text>
            </View>
            <View style={styles.header}>
              <Text style={styles.contributionText}>{contribution.input}</Text>
            </View>
            <View style={styles.header}>
              <Text variant={'titleMedium'} numberOfLines={1}>
                {t('Justification')}:
              </Text>
            </View>
            <View style={styles.header}>
              <Text style={styles.contributionMetaText}>
                {contribution.motivation}
              </Text>
            </View>
          </>
        )}
        {contribution.contributionType === ContributionType.Inquiry && (
          <>
            <View style={styles.header}>
              <Text variant={'titleMedium'} numberOfLines={1}>
                {t('Inquiry')}:
              </Text>
            </View>
            <View style={styles.header}>
              <Text style={styles.contributionMetaText}>
                {contribution.input}
              </Text>
            </View>
          </>
        )}
        {contribution.contributionType === ContributionType.Challenge && (
          <>
            <View style={styles.header}>
              <Text variant={'titleMedium'} numberOfLines={1}>
                {t('Challenge')}:
              </Text>
            </View>
            <View style={styles.header}>
              <Text style={styles.contributionMetaText}>
                {contribution.input}
              </Text>
            </View>
            <View style={styles.header}>
              <Text variant={'titleMedium'} numberOfLines={1}>
                {t('Reference')}:
              </Text>
            </View>
            <View style={styles.header}>
              <Text style={styles.contributionMetaText}>
                {contribution.motivation}
              </Text>
            </View>
          </>
        )}
        {(canDecide || contribution.response) && (
          <>
            <View style={[styles.header, { marginBottom: theme.spacing(0) }]}>
              <Text variant={'titleMedium'} numberOfLines={1}>
                {(isRequired ? '* ' : '') + t('Response')}:
              </Text>
            </View>
            {canDecide ? (
              <>
                <TextInput
                  mode={'outlined'}
                  value={newResponse}
                  onChange={({ nativeEvent }) =>
                    setNewResponse(nativeEvent.text)
                  }
                />
                <HelperText type={'info'} visible={isRequired}>
                  <Text>* - {t('Required')}</Text>
                </HelperText>
              </>
            ) : (
              <Text style={styles.contributionMetaText}>
                {contribution.response}
              </Text>
            )}
          </>
        )}
        <BumpButtons
          userId={userId}
          isContributor={userId === contribution.userId}
          contributionId={contribution.id}
        />
      </Card.Content>
      {canDecide && (
        <Card.Actions>
          <Button
            onPress={handleCensure}
            style={{ borderColor: theme.colors.danger.main }}
            textColor={theme.colors.danger.main}
          >
            {t('Censure')}
          </Button>
          <View style={{ flexGrow: 1 }} />
          <IconButton
            disabled={!canSubmit}
            onPress={() => {
              handleUpdateContributionStatus(ContributionStatus.Rejected).then()
            }}
            iconColor={'white'}
            style={{ backgroundColor: theme.colors.danger.main }}
            icon={IconReject}
          />
          <IconButton
            disabled={!canSubmit}
            onPress={() => {
              handleUpdateContributionStatus(ContributionStatus.Accepted).then()
            }}
            iconColor={'white'}
            style={{ backgroundColor: theme.colors.success.main }}
            icon={IconAccept}
          />
        </Card.Actions>
      )}
      {canCancel && (
        <Card.Actions style={{}}>
          <Button
            onPress={() => {
              handleUpdateContributionStatus(
                ContributionStatus.Cancelled
              ).then()
            }}
            style={styles.cancelButton}
            textColor={theme.colors.danger.main}
          >
            {t('Cancel')}
          </Button>
        </Card.Actions>
      )}
    </Card>
  )
}
