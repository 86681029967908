import * as React from 'react'
import { useCallback, useMemo } from 'react'
import { Button, Card, IconButton, Text } from 'react-native-paper'
import { Icon } from '../../../components/Icon'
import { Article, Cluster } from '../types'
import {
  IconAdd,
  IconClusterSettings,
  IconImport,
  IconPrivate,
} from '../../../utils/meldd-icons'
import {
  FlatList,
  ListRenderItemInfo,
  StyleSheet,
  View,
} from 'react-native'
import { useTranslation } from 'react-i18next'
import { useTheme } from '../../../hooks/use-theme'
import { PerspectiveTile } from './PerspectiveTile'
import * as Clipboard from 'expo-clipboard'
import { AppNavigation } from '../../../navigation/types'
import { useNavigation } from '@react-navigation/native'
import { importFile } from '../../../utils/import-utils'
import { UserLink } from './ClickableClusterMember'
import Toast from 'react-native-root-toast'
import { Env } from '../../../env'
import { useAuthContext } from '../../../auth/auth-context'

interface ClusterCardProps {
  cluster: Cluster
  onRefresh: () => void
  refreshing: boolean
}

export function ClusterCard(props: ClusterCardProps) {
  const { t } = useTranslation('clusters')
  const theme = useTheme()
  const navigation = useNavigation<AppNavigation>()
  const { authAction } = useAuthContext()
  const { cluster, refreshing, onRefresh } = props
  const styles = useMemo(
    () =>
      StyleSheet.create({
        card: {
          flex: 1,
          height: 1, // To trigger the flex on iOS
          flexDirection: 'column',
        },
        content: {
          backgroundColor: theme.colors.surface,
          flex: 1,
          paddingHorizontal: 0,
          margin: 0,
        },
        titleWrapper: {
          flex: 0,
          flexBasis: 'auto',
          flexDirection: 'row',
          paddingVertical: theme.spacing(1),
          paddingHorizontal: theme.spacing(4),
        },
        title: {
          flex: 1,
          alignItems: 'stretch',
        },
        mainTitle: {
          flexDirection: 'row',
          flexGrow: 1,
          alignItems: 'center',
        },
        button: {
          marginRight: -theme.spacing(1),
          justifyContent: 'center',
        },
        subTitle: {
          color: theme.colors.onSurfaceVariant,
          alignContent: 'center',
          flexDirection: 'row',
          columnGap: theme.spacing(1),
          marginTop: cluster.showModerators
            ? theme.spacing(0)
            : theme.spacing(3),
        },
        moderators: {
          flexDirection: 'row',
          marginTop: theme.spacing(1),
          overflow: 'hidden',
        },
        moderator: {
          flexShrink: 1,
        },
        actions: {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingVertical: theme.spacing(3),
          paddingHorizontal: theme.spacing(4),
        },
      }),
    [theme, cluster.showModerators]
  )

  const saveClusterUrl = (cluster: Cluster) => {
    let urlToClipBoard = `${Env.WEB_APP_URL}/ClusterCards?clusterId=${cluster.id}&nodeId=${cluster.nodeId}`

    Toast.show('URL copied!', {
      position: Toast.positions.TOP,
    })
    Clipboard.setString(urlToClipBoard)
  }

  const renderListItems = useCallback(
    (info: ListRenderItemInfo<Article>) => {
      return <PerspectiveTile cluster={cluster} perspective={info.item} />
    },
    [cluster.articles, cluster.articleVotes, cluster.showPerspectiveOwners]
  )
  return (
    <Card
      style={styles.card}
      elevation={5}
      mode={'elevated'}
      contentStyle={{ flex: 1 }}
    >
      <View style={styles.titleWrapper}>
        <View style={styles.title}>
          <View style={styles.mainTitle}>
            <Text
              style={{ flexShrink: 1 }}
              variant={'titleLarge'}
              numberOfLines={15}
              ellipsizeMode={'tail'}
            >
              {cluster.topic}
            </Text>
            <IconButton
              mode="contained-tonal"
              size={20}
              icon="content-copy"
              onPress={() => saveClusterUrl(cluster)}
            />
            {cluster.isModerator && (
              <IconButton
                style={styles.button}
                onPress={() =>
                  navigation.navigate('ClusterSettings', { cluster: cluster })
                }
                icon={IconClusterSettings}
              />
            )}
          </View>
          <View style={styles.subTitle}>
            {cluster.isPrivate ? (
              <>
                <Icon
                  source={IconPrivate}
                  size={16}
                  color={theme.colors.onSurfaceVariant}
                />
                <Text variant={'bodySmall'}>{t('Private')}</Text>
              </>
            ) : (
              <Text variant="bodySmall">{t('Public')}</Text>
            )}
          </View>
          {cluster.showModerators && (
            <View style={styles.moderators}>
              <Text variant={'bodySmall'}>{t('Moderators')}:</Text>
              {cluster.moderators.map(((moderator, index) => (
                <UserLink
                  key={index}
                  user={moderator.user}
                />
              )))}
            </View>
          )}
        </View>
      </View>
      <Card.Content style={styles.content}>
        <FlatList
          data={cluster.articles}
          renderItem={renderListItems}
          extraData={cluster.articleVotes}
          refreshing={refreshing}
          onRefresh={onRefresh}
        />
      </Card.Content>
      <View style={styles.actions}>
        <Button
          mode={'outlined'}
          icon={IconImport}
          onPress={() => {
            authAction(navigation, () => importFile(navigation, cluster.id))
          }
          }

        >
          {t('Import')}
        </Button>
        <Button
          mode="contained"
          icon={IconAdd}
          onPress={() => {
            authAction(navigation, () => navigation.navigate('CreateArticle', {
              clusterId: cluster.id,
              showOwnerCluster: cluster.showPerspectiveOwners,
            }))
          }}
        >
          {t('Perspective')}
        </Button>
      </View>
    </Card >
  )
}
