import * as React from 'react'
import { useCallback, useEffect } from 'react'
import {
  FlatList,
  ListRenderItemInfo,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import { useIsFocused } from '@react-navigation/native'
import { MdNode } from './types'
import { NodeListItem } from './components/NodeListItem'
import { StackScreenProps } from '@react-navigation/stack'
import { AppNavigatorParams } from '../../navigation/types'
import { useNode } from '../../contexts/CurrentNodeProvider'
import { useTranslation } from 'react-i18next'

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
})

export function ListNodesScreen({
  navigation,
}: StackScreenProps<AppNavigatorParams, 'ListNodes'>) {
  const isFocused = useIsFocused()
  const { nodes, refetch, loading, setCurrentNode } = useNode()
  const { t } = useTranslation('nodes')
  useEffect(() => {
    if (isFocused) {
      refetch().then()
    }
  }, [isFocused])
  const navigate = useCallback(
    (node: MdNode) => {
      setCurrentNode(node)
      navigation.pop()
    },
    [navigation]
  )

  const renderNodeListItem = useCallback((info: ListRenderItemInfo<MdNode>) => {
    return <NodeListItem node={info.item} onNavigate={navigate} />
  }, [])
  if (loading) {
    return (<View style={styles.container}>
      <Text>{t('Loading')}</Text>
    </View>)
  }
  if (!nodes.length) {
    return (<View>
      <View style={styles.container}>
        <Text>{t('No Nodes')}</Text>
      </View>
    </View>)
  }
  return (
    <FlatList data={nodes} renderItem={renderNodeListItem} />

  )
}
export default ListNodesScreen
